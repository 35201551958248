@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap');

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body Styling */
body {
  font-family: 'Inter', sans-serif;
  background-color: #f0f5ff;
}

.text-center {
  text-align: center;
}

.header-section {
  padding: 30px;
  background: #D1E8FF;
}

.header-section02 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.heading {
  color: #232E69;
  text-align: center;
  font-size: 45px;
  font-weight: 900;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.button-wrap {
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: center;
}

.button-header001 {
  width: 230px !important;
  padding: 15px !important;
  background: #369BFF !important;
  border-radius: 8px !important;
  border: 1px solid #369BFF !important;
  color: white !important;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.08) inset !important;
}

.button-header002 {
  width: 230px !important;
  padding: 15px !important;
  border-radius: 8px !important;
  background: #FFF !important;
  border: 1px solid #369BFF !important;
  color: #369BFF !important;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.08) inset !important;
}

.video-wrapper {
  border-radius: 10px;
  padding: 10px;
  background: #c9cfcf;
  z-index: 500;
}

/* Sign in styles */
.login-section {
  background: #F9F9F9;
  padding: 50px;
  border-radius: 50px 50px 0px 0px;
  position: relative;
  top: -155px;
  margin-bottom: -155px;
}

.create-account-section {
  background: #F9F9F9;
  padding: 50px;
  border-radius: 50px 50px 0px 0px;
  position: relative;
  top: -155px;
  margin-bottom: -155px;
}

.signin-page {
  background-color: #f5f7fa;
}

.signin-left {
  text-align: center;
  padding: 20px;
}

.welcome-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.welcome-text {
  color: #6c757d;
  font-size: 1rem;
  max-width: 400px;
}

.signin-right {}

.signin-form-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.signin-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.signup-link,
.forgot-password-link {
  color: #007bff;
  text-decoration: none;
}

.signup-link:hover,
.forgot-password-link:hover {
  text-decoration: underline;
}

.signin-btn {
  background-color: #007bff;
  border: none;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
}

.signin-btn:hover {
  background-color: #0056b3;
}

.paddingtop130 {
  padding-top: 130px;
}

.signup-page {
  background-color: #f5f7fa;
}

.signup-left {
  text-align: center;
  padding: 20px;
}

.welcome-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.welcome-text {
  color: #6c757d;
  font-size: 1rem;
  max-width: 400px;
}

.features {
  margin-top: 30px;
}

.feature {
  margin-bottom: 20px;
}

.feature-title {
  font-size: 1.2rem;
  margin-top: 10px;
}

.feature-text {
  color: #6c757d;
  font-size: 0.9rem;
}

.signup-right {}

.signup-form-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.signup-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.signin-link {
  color: #007bff;
  text-decoration: none;
}

.signin-link:hover {
  text-decoration: underline;
}

.signup-btn {
  background-color: #007bff;
  border: none;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
}

.signup-btn:hover {
  background-color: #0056b3;
}

/* Features Section */
.features-section {
  padding: 60px;
  background-color: #ffff;
  border-radius: 50px 50px 0px 0px;
  position: relative;
  top: -155px;
  margin-bottom: -155px;
  padding-top: 180px;
  transition: all 0.5s ease-in-out;
}

.login-active,
.create-account-active {
  position: block !important;
  top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 60px !important;
  transition: opacity 0.5s ease, max-height 0.5s ease;
}

.features-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.features-description {
  color: #6c757d;
  font-size: 1rem;
}

.features-subheading {
  font-size: 1rem;
  margin-bottom: 20px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
}

.feature-card {
  padding: 20px;
  text-align: center;
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  background-color: #e7f1ff;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

.feature-card .learn-more-link {
  color: #6f42c1;
  font-weight: bold;
  text-decoration: none;
}

.feature-card .learn-more-link:hover {
  text-decoration: underline;
}



.button-wrap {
  display: flex;
  flex-wrap: wrap; /* Allows buttons to wrap if needed */
  align-items: center;
  justify-content: center;
  gap: 25px;
}

/* Button 1 (Login) */
.button-header001,
.button-header002 {
  flex-grow: 1; /* Allow buttons to grow and shrink as needed */
  max-width: 230px;
  min-width: 150px;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
}

/* Login button specific styles */
.button-header001 {
  background: #369BFF;
  border: 1px solid #369BFF;
  color: white;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

/* Create an Account button specific styles */
.button-header002 {
  background: #FFF;
  border: 1px solid #369BFF;
  color: #369BFF;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

.video-wrapper {
  position: relative;
  width: 50%;
  padding: 15%;
  height: 0;
  border-radius: 10px;
  overflow: hidden;
  z-index: 500;
  margin: 0 auto;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}


@media (max-width: 500px) {
  .button-header001,
  .button-header002 {
    width: 100%; /* Full width for small screens */
    margin-bottom: 10px; /* Add spacing when stacked */
  }
}

@media (max-width: 768px) {    
  .features-heading {
    font-size: 2rem;
  }

  .feature-icon {
    width: 60px;
    height: 60px;
  }

  .features-section {
    padding: 90px 30px;
  }
  .more-features-section{
    padding-right: 30px;
    padding-left: 30px;
  }
  .footer-section{
    padding-right: 30px;
    padding-left: 30px;
    text-align: center;
  }
  .align-left.text-md-left.mb-4.mb-md-0.col-md-4{
    text-align: center;
  }
  .video-wrapper {
    width: 90%; 
    padding: 50%;
  }
  .header-section02{
    gap: 25px;
  }
  .login-section, .create-account-section{
    padding: 50px 5px;
  }
  .heading{
    font-size: 35px;
  }
}

.rect {
  width: 46px;
  height: 3px;
  background: #D1D0D6;
  margin-right: 15px;
}

/* Footer Section */

.footer-section {
  background-color: #ffff;
  /* Light gray background */
  padding: 40px 0;
}

.footer-logo {}

.footer-heading {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.footer-links {
  list-style: none;
  padding-left: 0;
}

.footer-links li {
  margin-bottom: 0.5rem;
}

.footer-links a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #007bff;
  /* Hover color */
}

.footer-style {
  align-items: flex-start;
}

.align-left {
  text-align: left;
}

.divider {
  width: 100%;
  height: 1px;
  background: #E8E7E8;
}

/* More Features Section */
.more-features-section {
  background-color: #ffffff;
}

.more-feature-card {
  padding: 20px;
  background-color: #ffff;
  border-radius: 8px;
  transition: all 0.3s ease;
  text-align: left;
}

.more-feature-card img {
  width: 60px;
  height: 60px;
}

.more-feature-card h5 {
  margin-top: 15px;
  font-size: 1.2rem;
}

.more-feature-card p {
  font-size: 0.9rem;
  color: #666;
}

.more-feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.align-center{
    text-align: center;
}

.features-heading-faq {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #369BFF;
    text-align: center;
  }