.card {
    border: 1px solid #e3e3e3d1;
}

.display-error {
    font-size: calc(20rem + 6vw);
    font-weight: 700;
    line-height: 1;
}

.height-center {
    height: 80vh;
    display: flex;
    align-items: center;
}

.head-error {
    font-weight: 600;
}

.subhead-error {
    font-weight: 400;
    font-size: 1.1rem;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-text {
    text-align: center;
}

.font-21 {
    font-size: 21px;
    position: relative;
    top: -1px;
}

.font-19 {
    font-size: 19px;
    position: relative;
    top: -1px;
}

.width-100 {
    width: 100%;
}

.h-80vh {
    min-height: 75vh !important;
}

.h-96 {
    height: 96% !important;
}

.verticalAds {
    .height-95 {
        height: 95%;
    }

    .height-100 {
        height: 100%;
    }

    .center-text {
        text-align: center;
    }
}

.vertical-menu {
    .heightAuto {
        height: auto;
        display: block;
    }

}

.vertical-collpsed .navbar-brand-box {
    width: 70px !important;
    padding-left: 0px;
    padding-right: 0px;
}

.main-content {
    .right-float {
        float: right;
    }

    .height100 {
        height: 100%;
    }

    .card {
        .card-title {
            font-size: 19px;
            font-weight: 400;
        }

    }
}

.fixed-height-match {
    height: 30.96rem;
}

.fixed-height-match2 {
    height: 22.62rem;
}

.navbar-header .app-search {
    width: 400px;

    input {
        border-radius: 10px;
        // padding: 7px 15px;
        border: 1px solid #E3E3E3;
        background-color: transparent;
    }
}


//Ads Manager Page
.model-width80 {
    width: 70%;
    max-width: 100vw;
}

.search-box .form-control {
    border-radius: 0.5rem;
}

//Profile page 
// .wizard .steps {

//     .nav-link,
//     .current a {
//         background: transparent;
//     }
// }

.widthCustom-80 {
    width: 80px;
}

.flex-center1 {
    display: flex;
    justify-content: center;
}

.padding-custom {
    padding: 12px 16px;
}

.required:after {
    content: " *";
    color: red;
}

.valid-select {
    /* Styles for valid select */
    border: red($color: #000000)
}

.invalid-select .css-13cymwt-control {
    border: #f46a6a 1px solid !important;
    border-radius: 5px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23f46a6a%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23f46a6a%27 stroke=%27none%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}



.invalid-feedback {
    display: block !important;
    ;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f46a6a;
}

.danger-btn {
    background-color: #f46a6a !important;
}


@media only screen and (min-width: 1200px) {
    .chat-leftsidebar {
        min-width: 310px;
        max-width: 310px;
    }
}

.border-rad {
    border-radius: 0.5rem !important;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex {
    display: flex;
}

.pr-07-icon {
    font-size: 23px !important;
    height: 23px;
    position: relative;
    top: -6px;
}

.pl-7-text {
    padding-left: 7px;
    font-weight: 600;
}

.padd-CustomRight {
    padding-right: 30px;
}

.padd-CustomLeft {
    padding-right: 30px;
}

.right-float-responsive {
    float: right;
}

.border-rad .input.active {
    border-radius: 0.5rem !important;
}

.text-center {
    text-align: center;
}

.vertical-middle {
    vertical-align: middle;
}

.font-400 {
    font-weight: 400;
}

.margin-custom-05 {
    margin-right: 5px;
    margin-left: 5px;
}

@media only screen and (max-width: 600px) {
    .right-float-responsive {
        float: left !important;
    }

    .condition-button {
        margin-bottom: 5px;
    }
}


//checkbox styles
.round {
    display: flex;
    justify-content: center;
    align-items: center;

    .checkbox__input {
        position: absolute;
        top: -100px;
        left: -100px;
    }

    .checkbox__inner {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #626262;
        background: transparent no-repeat center;
    }

    .checkbox__input:checked+.checkbox__inner {
        border-color: #34c38f;
        background-color: #34c38f;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='10px' viewBox='0 0 14 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3Echeck%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ios_modification' transform='translate(-27.000000, -191.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Group-Copy' transform='translate(0.000000, 164.000000)'%3E%3Cg id='ic-check-18px' transform='translate(25.000000, 23.000000)'%3E%3Cpolygon id='check' points='6.61 11.89 3.5 8.78 2.44 9.84 6.61 14 15.56 5.05 14.5 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: 14px 10px;
    }
}


// Side bar query builder UI

.sideSection .rule-card {
    display: flex !important;
    flex-direction: column !important;
}

.sideSection .adjust-col12 {
    flex: 0 0 auto !important;
    width: 100% !important;
}

.sideSection .adjust-col12 button {
    width: 100% !important;
}

.sideSection .deleteButcorr {
    float: none !important;
}

.buttongroupCorrection {
    width: 100% !important;
    padding: 0px 25px !important;
}

.sideSection .heightcorr{
    min-height: 160px;
}
.sideSection .textcorr{
    font-size: 17px;
}


@media print {
    html,
    body {
       display: none;
    }
 }

 .watermark {
    position: absolute;
    // top: 40%;
    // left: 20%;
    transform: translate(-50%, -50%);
    opacity: 0.03;
    z-index: 1;
    pointer-events: none; /* Prevent the watermark from blocking interactions */
    transform: rotate(-30deg);
    font-size: 35px;
    font-family:Arial, Helvetica, sans-serif;

    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  .watermark-form {
    position: absolute;
    // top: 10%;
    // left: 10%;
    // transform: translate(-50%, -50%);
    opacity: 0.03;
    z-index: 1;
    pointer-events: none; /* Prevent the watermark from blocking interactions */
    transform: rotate(-40deg);
    font-size: 70px;
    font-family:Arial, Helvetica, sans-serif;

    // top: 0;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  


  $AnimationSpeed: 0.6;
$Font-size:1.5rem;
$Colors: #ffc400, #ffc400, #ffab00, #ffc400, #006064 ;
// End of modify
/* */
/* Animation */
/* */
#hourglass{
  opacity: 1;
  color: #6a1b9a ;
  font-size:$Font-size;
  $_animationSpin:hourglass-spin ($AnimationSpeed * 4s) ease-out infinite;
  i{
    opacity: 0;
    animation: hourglass ($AnimationSpeed * 4s) ease-in infinite,
      $_animationSpin;
  }
  @for $i from 1 through 4{
    &>i:nth-child(#{$i}){
      color: nth($Colors, $i);
      animation-delay: $AnimationSpeed * ($i - 1) * 1s ,0s;
    }
  }
  &>i:nth-child(4){
    animation: hourglass-end ($AnimationSpeed * 4s) ease-in infinite,
      $_animationSpin;
  }
  &>i:nth-child(5){
    color:nth($Colors, 5);
    opacity:1;
    animation: $_animationSpin;
  }
}
@keyframes hourglass{
  0% { opacity: 1 }
  24% { opacity: 0.9}
  26% { opacity: 0 }
}
@keyframes hourglass-end {
  0% { opacity: 0 }
  70% { opacity: 0 }
  75% { opacity: 1}
  100% { opacity: 1}
}
@keyframes hourglass-spin {
  75% { transform: rotate(0deg); }
  100% { transform: rotate(180deg); }
}

.accordian-complaints{
    margin-left: -12px;
    margin-right: -12px
}

.justify-row{
    justify-content: center;
}